<template>
  <v-layout class="patient-page" style="margin-bottom:60px;" justify-center>
    <v-flex xs12 sm10 md8 lg6>
      <div style="padding:10px;" v-html="rawHtml" /> </v-flex
  ></v-layout>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  data: () => ({
    rawHtml: ''
  }),
  methods: {
    getHTML() {
      axios.get(this.termsURL).then(({ data }) => {
        this.rawHtml = data
      })
    }
  },
  watch: {
    termsURL() {
      this.getHTML()
    }
  },
  computed: {
    ...mapState('auth', ['termsURL'])
  },
  mounted() {
    this.getHTML()
  }
}
</script>

<style></style>
